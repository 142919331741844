<template>
  <b-card>
    <div>
      <b-row>
        <div v-if="!showNegotiation">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showNegotiation = true"
            title="Total"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="DollarSignIcon" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showNegotiation = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>

        <div v-if="!showTitles">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showTitles = true"
            title="Título"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="ListIcon" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showTitles = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>

        <div v-if="!showClients">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showClients = true"
            title="Clientes"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="UsersIcon" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showClients = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
        <div v-if="!showSearch">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showSearch = true"
            title="Pesquisa"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showSearch = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
        <b-col v-show="showSearch">
          <b-form-input
            placeholder="Pesquisar"
            v-model="search"
            debounce="800"
            type="search"
          />
        </b-col>
      </b-row>

      <div>
        <b-row class="justify-content-md-center">
          <b-col md="6" xl="4" class="p-1" v-show="showNegotiation">
            <statistic-card-vertical
              hide-chart
              color="info"
              icon="DollarSignIcon"
              :statistic="negotiationHeader.total_value"
              statistic-title="Total negociado"
            />
          </b-col>
          <b-col md="6" xl="4" class="p-1" v-show="showTitles">
            <statistic-card-vertical
              hide-chart
              color="success"
              icon="ListIcon"
              :statistic="negotiationHeader.total_title"
              statistic-title="Títulos negociados"
            />
          </b-col>
          <b-col md="6" xl="4" class="p-1" v-show="showClients">
            <statistic-card-vertical
              hide-chart
              color="warning"
              icon="UsersIcon"
              :statistic="negotiationHeader.total_client"
              statistic-title="Clientes que negociaram"
            />
          </b-col>
        </b-row>
      </div>
      <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
        <b-table-simple
          striped
          stacked="md"
          noDataText="Você ainda não possui clientes cadastrados."
          class="mt-2 responsive"
          id="tableNegotiations"
          :item="fetchNegotiations"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <b-thead class="text-center">
            <b-th> Data e Hora </b-th>
            <b-th> Cód. Contrato </b-th>
            <b-th> Cliente </b-th>
            <b-th> Valor pago </b-th>
            <b-th> Parcelas </b-th>
            <b-th> Método </b-th>
            <b-th> Ações </b-th>
          </b-thead>
          <b-tbody>
            <b-tr :key="indextr" v-for="(tr, indextr) in negotiation">
              <b-td> {{ tr.created_at | dateTimeFormat }} </b-td>
              <b-td> {{ tr.id }} </b-td>
              <b-td> {{ tr.client.name }} </b-td>
              <b-td> {{ tr.total_value | moneyFormat }} </b-td>
              <b-td> {{ tr.plots }} </b-td>
              <b-td> {{ tr.payment_method }} </b-td>
              <b-td>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-warning"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                  v-b-modal.modal-delete-tenants
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
      <b-pagination
        @change="handlePageChange"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        aria-controls="tableNegotiations"
        class="mt-1"
      />
    </div>
  </b-card>
</template>

<script>
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
import { BButton } from "bootstrap-vue";
// import vSelect from "vue-select";

export default {
  components: {
    BButton,
    StatisticCardVertical,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      showNegotiation: false,

      showTitles: false,

      showClients: false,

      showSearch: false,

      search: null,

      isLoading: true,

      // selected: null,
      // options: [
      //   { value: null, text: "Status" },
      //   { value: "a", text: "Finalizada" },
      //   { value: "b", text: "Pendente" },
      //   { value: "c", text: "Não paga" },
      // ],
    };
  },
  computed: {
    ...mapState("negotiations", ["negotiation", "pagination"]),
    ...mapState("negotiations", ["negotiationHeader"])
    },
  methods: {
    ...mapActions("negotiations", ["fetchNegotiationHeader"]),
    fetchNegotiations(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("negotiations/fetchNegotiation", {
          paginated: paginated,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })

        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handlePageChange(value) {
      this.fetchNegotiations(true, value);
    },
  },
  created() {
    this.fetchNegotiations();
    this.fetchNegotiationHeader();
  },
  watch: {
    search(val) {
      this.currentPage = 1;
      this.fetchNegotiations(true, 1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
